import React, { useState, useEffect } from "react";
import { collection, setDoc, doc, updateDoc, getDocs, deleteDoc, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { signOut } from "firebase/auth";
import { auth } from "./firebase";
import * as XLSX from "xlsx";
import "./Dashboard.css"; // インポート

const Dashboard = ({ user }) => {
  const [records, setRecords] = useState([]);
  const [workType, setWorkType] = useState("現場");
  const [clockIn, setClockIn] = useState("09:00");
  const [clockOut, setClockOut] = useState("17:45");
  const [totalHours, setTotalHours] = useState(0);

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
  const [day, setDay] = useState((new Date().getDate()).toString().padStart(2, '0'));

  const years = Array.from({ length: 20 }, (_, i) => (new Date().getFullYear() - 10 + i).toString());
  const months = Array.from({ length: 12 }, (_, i) => (i + 1).toString().padStart(2, '0'));

  const updateDays = (year, month) => {
    const lastDay = new Date(year, month, 0).getDate();
    const days = Array.from({ length: lastDay }, (_, i) => (i + 1).toString().padStart(2, '0'));
    return days;
  };

  useEffect(() => {
    const days = updateDays(year, month);
    if (parseInt(day) > days.length) {
      setDay(days[days.length - 1]);
    }
  }, [year, month]);

  useEffect(() => {
    fetchRecords();
  }, [user, year, month]);

  const fetchRecords = async () => {
    const startDate = `${year}-${month}-01`;
    const endDate = `${year}-${month}-${new Date(year, month, 0).getDate()}`;

    const recordsRef = collection(db, "time_cards", user.uid, "records");
    const q = query(recordsRef, where("date", ">=", startDate), where("date", "<=", endDate));
    const snapshot = await getDocs(q);
    const recordsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setRecords(recordsData);
    calculateTotalHours(recordsData);
  };

  const handleClockIn = async () => {
    const date = `${year}-${month}-${day}`;
    await setDoc(doc(db, "time_cards", user.uid, "records", date), {
      date,
      workType,
      clockIn,
      clockOut: null
    }, { merge: true });
    fetchRecords();
  };

  const handleClockOut = async () => {
    const date = `${year}-${month}-${day}`;
    await updateDoc(doc(db, "time_cards", user.uid, "records", date), {
      clockOut
    });
    fetchRecords();
  };

  const handleLogout = async () => {
    await signOut(auth);
  };

  const handleDeleteRecord = async (recordId) => {
    await deleteDoc(doc(db, "time_cards", user.uid, "records", recordId));
    fetchRecords();
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: "array" });

    // "yM"形式のシート名
    const sheetName = Object.keys(workbook.Sheets).find(sheet => sheet.includes(`${String(year).slice(2)}${month}`));
    if (!sheetName) {
      alert(`シート名に "${String(year).slice(2)}${month}" を含むシートが見つかりません。`);
      return;
    }

    const worksheet = workbook.Sheets[sheetName];

    // レコードをシートに書き込む
    records.forEach(record => {
      const date = new Date(record.date);
      const row = 12 + date.getDate();  // 1日の勤怠は13行目から始まる
      worksheet[`C${row}`] = { t: "s", v: record.clockIn };
      worksheet[`D${row}`] = { t: "s", v: record.clockOut };
      worksheet[`E${row}`] = { t: "s", v: "12:00" };
      worksheet[`F${row}`] = { t: "s", v: "13:00" };
    });

    // ファイルを保存
    const bookType = file.name.endsWith('.xlsm') ? 'xlsm' : 'xlsx';
    const updatedData = XLSX.write(workbook, { bookType, type: "array" });
    const blob = new Blob([updatedData], { type: "application/octet-stream" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = file.name;
    link.click();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', weekday: 'short' };
    return date.toLocaleDateString('ja-JP', options).replace('/', '日(').replace(' ', ')');
  };

  const calculateTotalHours = (recordsData) => {
    let totalMinutes = 0;

    recordsData.forEach(record => {
      if (record.clockIn && record.clockOut) {
        const clockInTime = new Date(`1970-01-01T${record.clockIn}:00`);
        const clockOutTime = new Date(`1970-01-01T${record.clockOut}:00`);
        const diff = (clockOutTime - clockInTime) / 1000 / 60 - 60; // subtract 60 minutes for break
        totalMinutes += diff;
      }
    });

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    setTotalHours(`${hours}時間${minutes}分`);
  };

  const days = updateDays(year, month);

  return (
    <div className="dashboard">
      <h1>ようこそ, {user.email}さん</h1>
      <span className="logout-link" onClick={handleLogout}>ログアウト</span>
      <div className="form-row">
        <div className="form-group">
          <label>年:</label>
          <select value={year} onChange={(e) => setYear(e.target.value)}>
            {years.map((yr) => (
              <option key={yr} value={yr}>{yr}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>月:</label>
          <select value={month} onChange={(e) => setMonth(e.target.value)}>
            {months.map((mn) => (
              <option key={mn} value={mn}>{mn}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>日:</label>
          <select value={day} onChange={(e) => setDay(e.target.value)}>
            {days.map((dy) => (
              <option key={dy} value={dy}>{dy}</option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label>出社区分:</label>
          <select value={workType} onChange={(e) => setWorkType(e.target.value)}>
            <option value="現場">現場</option>
            <option value="自社">自社</option>
            <option value="テレワーク">テレワーク</option>
          </select>
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <label>出勤時間:</label>
          <input type="time" value={clockIn} onChange={(e) => setClockIn(e.target.value)} />
        </div>
        <div className="form-group">
          <label>退勤時間:</label>
          <input type="time" value={clockOut} onChange={(e) => setClockOut(e.target.value)} />
        </div>
      </div>
      <div className="button-row">
        <button onClick={handleClockIn}>出勤</button>
        <button onClick={handleClockOut}>退勤</button>
      </div>

      <h2>勤怠管理票に上書きする</h2>
      <input type="file" accept=".xlsx, .xls, .xlsm" onChange={handleFileUpload} />

      <h2>勤怠記録（合計: {totalHours}）</h2>
      <ul>
        {records.map((record, index) => (
          <li key={index}>
            <div className="record-details">
              <div>{formatDate(record.date)}</div>
              <div>{record.workType}</div>
              <div>{record.clockIn}</div>
              <div>{record.clockOut}</div>
            </div>
            <button onClick={() => handleDeleteRecord(record.id)}>削除</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dashboard;
